







































































































































































































































































































































































































import {
  computed,
  defineComponent,
  inject,
  nextTick,
  provide,
  reactive,
  Ref,
  ref,
  watch,
  onMounted
} from "@vue/composition-api";
import {
  ProjectStatus,
} from '@/api/project/model'
import { useProjectStore } from '@/pinia/modules/project'
import { storeToRefs } from 'pinia'
import { getAnalysisOpenSummaries } from '@/api/project'
import AnalyzeAskOpinion from "@/components/form-common-analyze/AnalyzeAskOpinion.vue";
import AnalyzeConceptAskOpinion from "@/components/form-common-analyze/AnalyzeConceptAskOpinion.vue";
import CalculateTime from "@/components/CalculateTime.vue";
import WordCloud from "@/components/WordCloud.vue";
import { isNil } from "lodash";
import { AnalyzeTab, wordCloudTags, WordCloudType, wordColors } from "@/utils/word-cloud";
import { getHighlightText, showError } from "@/utils/common";

export default defineComponent({
  components: { AnalyzeAskOpinion, AnalyzeConceptAskOpinion, CalculateTime, WordCloud },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { root }) {
    const head = inject("head");
    const markdownSummary: Ref<any> = ref(null)
    const projectStore = useProjectStore()
    const { projectInfo } = storeToRefs(projectStore)
    const messageItem: any = props.item;
    provide("messageItem", messageItem);

    const observerInfo: any = inject("observerInfo", { isObserver: false });

    const wordCloudRef: Ref<any> = ref(null);

    const contentMngInfo = reactive({
      contentType: AnalyzeTab.SupportRate,
    });

    const onShowWordCloudDialog = observerInfo.isObserver
      ? () => { }
      : inject<(message_entity_id: number) => void>("onShowWordCloudDialog");

    if (!onShowWordCloudDialog) {
      throw new Error("onShowWordCloudDialog is not provided");
    }
    const onShowAIAnalyzeDialog = observerInfo.isObserver
      ? () => { }
      : inject<(message_entity_id: number) => void>("onShowAIAnalyzeDialog");


    if (!onShowAIAnalyzeDialog) {
      throw new Error("onShowAIAnalyzeDialog is not provided");
    }
    const roleMng: any = reactive({
      showConfig: computed(() => {
        return !observerInfo.isObserver;
      }),
      allowShowWordCloud: computed(() => {
        const wordCloud = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return false;
        }
        if (observerInfo.isObserver) {
          return observerInfo.shareInfo?.keyword && wordCloud.nlp_settings.keywords_enable;
        } else {
          return (
            wordCloud.nlp_settings.keywords_enable
          );
        }
      }),
      allowShowAiAnalyse: computed(() => {
        const aiAnalyse = props.item?.message.ai_settings;
        if (isNil(aiAnalyse)) {
          return false;
        }
        if (observerInfo.isObserver) {
          return observerInfo.shareInfo?.ai && aiAnalyse.ai_enable;
        } else {
          return aiAnalyse.ai_enable;
        }
      }),
      allowShow: (type: WordCloudType) => {
        const item = type === WordCloudType.Keywords ? "keyword" : "nlp";
        const res = observerInfo.shareInfo?.[item];
        return !observerInfo.isObserver || res;
      },
    });


    const wordCloudInfo = reactive({
      isLoading: computed(() => {
        return props.item?.word_cloud?.loading;
      }),
      list: [] as any[],
      shape: computed(() => {
        return props.item?.word_cloud?.nlp_settings?.shape ?? "circle";
      }),
      colors: computed(() => {
        const color = props.item?.word_cloud?.nlp_settings?.color;
        return wordColors.find((item) => item.color === color)?.list ?? ["#000000"];
      }),
      currentTag: WordCloudType.Keywords,
      getTagClass: (tag: string) => {
        return tag === wordCloudInfo.currentTag ? "active" : "";
      },
      typeTags: computed(() => {
        const tags = [] as any[];
        const wordCloud: any = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return tags;
        }

        if (
          wordCloud.nlp_settings?.keywords_enable &&
          roleMng.allowShow(WordCloudType.Keywords)
        ) {
          tags.push(wordCloudTags.find((tag) => tag.value === WordCloudType.Keywords));
        }
        if (wordCloud.nlp_settings?.nlp_enable && roleMng.allowShow(WordCloudType.Nlp)) {
          tags.push(wordCloudTags.find((tag) => tag.value === WordCloudType.Nlp));
        }

        if (
          tags.length > 0 &&
          !tags.find((tag) => tag.value === wordCloudInfo.currentTag)
        ) {
          wordCloudInfo.currentTag = tags[0].value;
        }

        return tags;
      }),
      tagClick: (tag: any) => {
        wordCloudInfo.currentTag = tag.value;
      },
      keywordTags: [] as any[],
      nlpTags: [] as any[],
      participants: computed(() => {
        if (props.item?.collection) {
          return props.item.collection.result.participants;
        }
        return 0;
      }),
      opinions: computed(() => {
        if (props.item?.collection) {
          let ops: any[] = Object.values(props.item.collection.result?.opinions) ?? [];
          const retrieveMap =
            props.item?.word_cloud?.[`${wordCloudInfo.currentTag}_retrieve`];
          if (retrieveMap) {
            const tags: any[] = wordCloudInfo.getWordTags();
            const idSet = new Set();
            if (tags.length > 0) {
              const keys = tags.map((tag) => tag.name);
              keys.forEach((key) => {
                const item = retrieveMap[key];
                if (item && item.frequency > 0) {
                  item.replies.forEach((id: any) => idSet.add(Number(id)));
                }
              });
              ops = ops.filter((op) => idSet.has(op.reply_id));
            }
          }

          return ops;
        }
        return [];
      }),
      wordList: computed(() => {
        const wordCloud = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return [];
        }
        const result: any = wordCloud[`${wordCloudInfo.currentTag}_retrieve`];
        if (isNil(result)) {
          return [];
        }
        const arr = Object.keys(result).map((key) => {
          const item = result[key];
          return {
            name: key,
            value: item.frequency,
          };
        });
        return arr;
      }),
      getHighlightTags: (opinion: any) => {
        const wordCloud = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return [];
        }
        const result: any = wordCloud[`${wordCloudInfo.currentTag}_retrieve`];
        if (isNil(result)) {
          return [];
        }
        const hightlightSet = new Set();
        const tags: any[] = wordCloudInfo.getWordTags();
        tags.forEach((tag) => {
          const item = result[tag.name];
          if (item.replies.includes(opinion.reply_id)) {
            item.highlight_tags?.forEach((tag: any) => {
              hightlightSet.add(tag);
            });
          }
        });
        return Array.from(hightlightSet);
      },
      downloadWord: () => wordCloudRef.value.downloadWord(),
      refreshWord: () => wordCloudRef.value.initViewData(),
      clickWord: (params: any) => {
        const { data } = params;
        const tags: any[] = wordCloudInfo.getWordTags();
        if (tags.length >= 6 || tags.some((v) => v.name == data.name)) {
          return;
        }
        wordCloudInfo.getWordTags().push(data);
      },
      clearAllWork: () => {
        const tags = wordCloudInfo.getWordTags();
        tags.splice(0, tags.length);
      },
      clearWork: (tag: any, i: number) => {
        const tags: any[] = wordCloudInfo.getWordTags();
        tags.splice(i, 1);
      },
      getWordTags() {
        return wordCloudInfo.currentTag == WordCloudType.Keywords
          ? wordCloudInfo.keywordTags
          : wordCloudInfo.nlpTags;
      },
    });
    wordCloudInfo.list = wordCloudInfo.wordList;

    const drawerInfo = reactive({
      isAnalyze: false,
      showDrawer: false,
      onOpen: () => {
        drawerInfo.isAnalyze = true;
        nextTick(() => {
          drawerInfo.showDrawer = true;
        });
      },
      onClose: () => {
        drawerInfo.showDrawer = false;
        nextTick(() => {
          drawerInfo.isAnalyze = false;
        });
      },
    });
    const talkMngObj = reactive({
      participants: computed(() => {
        if (props.item?.collection) {
          return props.item.collection.result.participants;
        }
        return 0;
      }),
      opinions: computed(() => {
        if (props.item?.collection) {
          const ops: any[] = Object.values(props.item.collection.result?.opinions) ?? [];
          if (ops.length > 1) {
            ops.sort((a, b) => {
              if (a.agree_rate > b.agree_rate) {
                return -1;
              } else if (a.agree_rate < b.agree_rate) {
                return 1;
              } else {
                if (a.id > b.id) {
                  return -1;
                } else if (a.id < b.id) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return ops;
        }
        return [];
      }),
      getPercent: (agree_rate: number) => {
        return (agree_rate * 100).toFixed(0);
      },
      respondents: (token: any) => {
        if (props.item?.respondents) {
          const ops: any[] = props.item.respondents.filter((item: any) => item.token == token)
          if (ops.length > 0) {
            const seenIds = new Set();
            let segments = ops[0]
            let token_segments = segments.token_segments ? segments.token_segments : [];
            const uniqueData = token_segments.filter((item: any) => {
              if (!seenIds.has(item.id)) {
                seenIds.add(item.id);
                return true;
              }
              return false;
            });
            segments.uniqueData = uniqueData
            return segments
          } else {
            return 0
          }
        } else {
          return 0
        }
      }
    });

    const liveObj: any = inject("liveObj");
    const time = computed(() => {
      let remain = 0;
      // console.log(liveObj,props.item,"1223")
      // if (liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id) {
      //     remain = liveObj?.timeInfo?.timeRemain
      // }
      if (
        liveObj?.timeInfo?.id == props.item?.message?.id &&
        liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id
      ) {
        return (remain = liveObj?.timeInfo?.timeRemain);
      }
      return remain;
    });

    const copy_text = () => {
      root.$doCopy(
        talkMngObj.opinions
          .map((i: any) => '"' + i.text.replace('"', '"') + '"')
          .join("\n")
      );
    };

    const copyMarkdownSummary = () => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = root.$markdownToHtml(markdownSummary.value.summary);

      // 从div中提取纯文本
      const textToCopy = tempDiv.textContent || '';
      root.$doCopy(textToCopy);
      tempDiv.remove();
    };



    const refreshAnalysis = async () => {
      try {
        if (!!markdownSummary.value) {
          const result = await getAnalysisOpenSummaries({ id: markdownSummary.value.id })
          markdownSummary.value = result
        }
      } catch (error) {
        showError(error)
      }
    }

    watch(
      () => roleMng.allowShowWordCloud,
      (val) => {
        if (!val) {
          contentMngInfo.contentType = AnalyzeTab.SupportRate;
        }
      }
    );

    watch(
      () => roleMng.allowShowAiAnalyse,
      (val) => {
        if (!val) {
          contentMngInfo.contentType = AnalyzeTab.SupportRate;
        }
      }
    );
    watch(
      () => props.item?.collection?.ai_analysis_result,
      (val, old) => {
        const isChange = JSON.stringify(val) !== JSON.stringify(old);
        if (isChange) {
          markdownSummary.value = val ? val.open_ended_summary : null
        }
      },
      { deep: true }
    );


    watch(
      () => wordCloudInfo.wordList,
      (val, old) => {
        const isChange = JSON.stringify(val) !== JSON.stringify(old);
        if (isChange) {
          wordCloudInfo.list = val;
        }
      },
      { deep: true }
    );

    onMounted(() => {
      markdownSummary.value = props.item?.collection?.ai_analysis_result?.open_ended_summary ?? null
    });

    return {
      head,
      talkMngObj,
      time,
      drawerInfo,
      wordCloudRef,
      wordCloudInfo,
      onShowWordCloudDialog,
      onShowAIAnalyzeDialog,
      AnalyzeTab,
      contentMngInfo,
      getHighlightText,
      roleMng,
      copy_text,
      markdownSummary,
      refreshAnalysis,
      projectInfo,
      ProjectStatus,
      copyMarkdownSummary
    };
  },
});
