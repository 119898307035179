

















































































































































import { getMessageOptionsAnalyzeApi, getMessageSegmentAnalyzeApi, getAnalysisImageTags } from "@/api/project";
import { OrderType } from "@/api/project/model";
import { useProjectStore } from "@/pinia/modules/project";
import { getPercentageVarietyStyle } from "@/utils/percentage-color";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  Ref,
} from "@vue/composition-api";
import { Drawer, Dropdown, DropdownMenu, DropdownItem, Empty } from "element-ui";
import { isEqual, isNil, cloneDeep } from "lodash";
import Filters from "./components/Filters.vue";
import WordCloud from "@/components/WordCloudImage.vue";
import { wordCloudTags, WordCloudType, AnalyzeTab, wordColors } from "@/utils/word-cloud";
import { getHighlightText, showError } from "@/utils/common";
import { storeToRefs } from 'pinia'
import {
  ProjectStatus
} from '@/api/project/model'
export default defineComponent({
  components: {
    [Drawer.name]: Drawer,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Empty.name]: Empty,
    Filters,
    WordCloud,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { root, emit }) {
    const showDrawer = ref(props.value);
    const onClosed = () => {
      emit("input", showDrawer.value);
    };
    const showFilters = ref(false);

    const segments: any = inject("segments");
    const driver: any = inject("driver");

    const observerInfo: any = inject("observerInfo", { isObserver: false });

    const projectStore = useProjectStore();
    const { projectInfo } = storeToRefs(projectStore)

    const {
      getAnalyzeSegments,
      updateAnalyzeSegments,
      getFilterSegments,
      updateFilterSegments,
    } = projectStore;

    const onShowWordCloudDialog = observerInfo.isObserver
      ? () => { }
      : inject<(message_entity_id: number) => void>("onShowWordCloudDialog");

    if (!onShowWordCloudDialog) {
      throw new Error("onShowWordCloudDialog is not provided");
    }

    const onShowAIAnalyzeDialog = observerInfo.isObserver
      ? () => { }
      : inject<(message_entity_id: number) => void>("onShowAIAnalyzeDialog");

    if (!onShowAIAnalyzeDialog) {
      throw new Error("onShowAIAnalyzeDialog is not provided");
    }

    const contentType = ref(AnalyzeTab.AIAnalyze);
    const wordCloudRef: Ref<any> = ref(null);

    const roleMng: any = reactive({
      showConfig: computed(() => {
        return !observerInfo.isObserver;
      }),
      allowShowWordCloud: computed(() => {
        const wordCloud = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return false;
        }
        if (observerInfo.isObserver) {
          return (
            (observerInfo.shareInfo?.nlp && wordCloud.nlp_settings.nlp_enable) ||
            (observerInfo.shareInfo?.keyword && wordCloud.nlp_settings.keywords_enable)
          );
        } else {
          return (
            wordCloud.nlp_settings.keywords_enable || wordCloud.nlp_settings.nlp_enable
          );
        }
      }),
      allowVoting: computed(() => {
        try {
          return props.item?.message.settings[0].recommend_enable;
        } catch (error) {
          return true;
        }
      }),
      allowShow: (type: WordCloudType) => {
        const item = type === WordCloudType.Keywords ? "keyword" : "nlp";
        const res = observerInfo.shareInfo?.[item];
        return !observerInfo.isObserver || res;
      },
      isAllowRefresh: () => {
        if (props.item?.collection) {
          const statuses = ['done', 'error'];
          const incompleteStatuses = ['ready', 'waiting', 'analyzing'];
          const hasIncomplete = wordCloudInfo.imageTags.some((item: any) => incompleteStatuses.includes(item.status));
          if (hasIncomplete) return false;
          return wordCloudInfo.imageTags.every((item: any) => statuses.includes(item.status));
        }
        return false;
      },
      allowRefresh: false
    });

    const supportRateInfo: any = reactive({
      answerSegment: {
        id: 0,
        name: root.$t("all"),
        answers: [] as any[],
      },
      segments: [] as any[],
      orderObj: {
        type: OrderType.None,
        curOrderSegment: {} as any,
        getType: (segment: any) => {
          if (segment.id !== supportRateInfo.orderObj.curOrderSegment.id) {
            return OrderType.None;
          }
          return supportRateInfo.orderObj.type;
        },
        clearAllOrder: () => {
          supportRateInfo.orderObj.type = OrderType.None;
          supportRateInfo.orderObj.refreshOrder();
        },
        changeOrder: (segment: any, orderType?: OrderType) => {
          if (isNil(segment.id)) {
            return;
          }

          if (segment.id != supportRateInfo.orderObj.curOrderSegment.id) {
            supportRateInfo.orderObj.type = OrderType.None;
            supportRateInfo.orderObj.curOrderSegment = segment;
          }

          let keys = supportRateInfo.answerSegment.answers.map(
            (answer: any) => answer.reply_id
          );
          if (keys.length == 0) {
            return;
          }

          if (!orderType) {
            if (supportRateInfo.orderObj.type == OrderType.None) {
              orderType = OrderType.DESC;
            } else if (supportRateInfo.orderObj.type == OrderType.DESC) {
              orderType = OrderType.ASC;
            } else if (supportRateInfo.orderObj.type == OrderType.ASC) {
              orderType = OrderType.None;
            }
          }
          supportRateInfo.orderObj.type = orderType;

          const type = supportRateInfo.orderObj.type;
          const opinions = analyzeObj.getOpinions(segment);
          switch (type) {
            case OrderType.None:
              keys = keys.sort((a: any, b: any) => a - b);
              break;
            case OrderType.DESC:
              keys.sort((a: any, b: any) => {
                if (opinions[a]?.agree_rate === opinions[b]?.agree_rate) {
                  return a - b;
                } else {
                  return opinions[b]?.agree_rate - opinions[a]?.agree_rate;
                }
              });
              break;
            case OrderType.ASC:
              keys.sort((a: any, b: any) => {
                if (opinions[a].agree_rate === opinions[b].agree_rate) {
                  return (
                    supportRateInfo.answerSegment.answers.find(
                      (answer: any) => answer.reply_id === a
                    ).reply_id -
                    supportRateInfo.answerSegment.answers.find(
                      (answer: any) => answer.reply_id === b
                    ).reply_id
                  );
                } else {
                  return opinions[a]?.agree_rate - opinions[b]?.agree_rate;
                }
              });
              break;
          }
          supportRateInfo.answerSegment.answers.sort((a: any, b: any) => {
            return keys.indexOf(a.reply_id) - keys.indexOf(b.reply_id);
          });
        },
        refreshOrder: () => {
          supportRateInfo.orderObj.changeOrder(
            supportRateInfo.orderObj.curOrderSegment,
            supportRateInfo.orderObj.type
          );
        },
        canReset: computed(() => {
          return supportRateInfo.orderObj.type != OrderType.None;
        }),
      },

      filterSegment: {
        formIndex: 0,
        segments: [] as any[],
        curFilter: computed(() => {
          if (supportRateInfo.filterSegment.segments.length == 0) {
            return null;
          }
          return supportRateInfo.filterSegment.segments[
            supportRateInfo.filterSegment.formIndex
          ];
        }),
        getName: (selected: any[]) => {
          if (selected.length == 0) {
            return "--";
          }
          return selected
            .map((item: any) => {
              return item.text;
            })
            .join(",");
        },
        handleChange: async (options: any[]) => {
          supportRateInfo.filterSegment.curFilter.selected = options;
          supportRateInfo.filterSegment.curFilter.name = supportRateInfo.filterSegment.getName(
            options
          );
          await setCollection(supportRateInfo.filterSegment.curFilter);
          supportRateInfo.orderObj.refreshOrder();
        },
        handleAnswerChange: async (options: any[]) => {
          supportRateInfo.filterSegment.curFilter.selected = options;
          supportRateInfo.filterSegment.curFilter.name = supportRateInfo.filterSegment.getName(
            options
          );
          await setCollection(supportRateInfo.filterSegment.curFilter, true);
          supportRateInfo.orderObj.refreshOrder();
        },
        reset: async () => {
          supportRateInfo.filterSegment.curFilter.selected = [];
          supportRateInfo.filterSegment.curFilter.name = "--";
          setCollection(supportRateInfo.filterSegment.curFilter);
          await supportRateInfo.orderObj.clearAllOrder();
        },
      },

      handleSegmentChange: async (segment: any) => {
        supportRateInfo.answerSegment.name = segment.name;
        supportRateInfo.answerSegment.id = segment.id;
        supportRateInfo.answerSegment.answers = await supportRateInfo.getSegmentAnswers(
          segment.id
        );
        supportRateInfo.orderObj.refreshOrder();
      },
      getSegmentAnswers: async (segmentId: number) => {
        if (segmentId == 0) {
          return props.item?.collection?.result.opinions
            ? Object.values(props.item.collection?.result.opinions)
            : [];
        }
        const res = await getMessageSegmentAnalyzeApi({
          uuid: props.item?.message.project_uuid,
          mid: props.item?.message.message_identifier,
          id: segmentId,
          display: "none",
          driver: driver.value,
          observer: observerInfo.isObserver,
          analysis_type: contentType.value
        });
        return res[0]?.collection?.result?.opinions
          ? Object.values(res[0].collection.result.opinions)
          : [];
      },

      handleCommand: async (item: any) => {
        let segmentItem = analyzeObj.originSegments.find((segment: any) => {
          return segment.id === item.segment.id;
        });
        if (segmentItem) {
          const cloneItem = cloneDeep(segmentItem);
          await setCollection(cloneItem);
          item.index = item.index ?? supportRateInfo.filterSegment.formIndex ?? 0;
          supportRateInfo.segments.splice(item.index, 1, cloneItem);
        }
      },
      segmentAnswers: [] as any[],
      filterAnswers: computed(() => {
        if (showFilters.value) {
          if (supportRateInfo.filterSegment.curFilter?.collection) {
            return (
              Object.values(
                supportRateInfo.filterSegment.curFilter.collection.result?.opinions
              ) ?? []
            );
          }
        } else {
          if (supportRateInfo.curSegment?.collection) {
            return (
              Object.values(supportRateInfo.curSegment.collection.result?.opinions) ?? []
            );
          }
        }

        return [];
      }),
      showAnswers: async (item: any) => {
        if (!item.segment) {
          const segment = supportRateInfo.filterSegment.segments[item.index];
          supportRateInfo.segments[item.index] = segment;
          supportRateInfo.filterSegment.formIndex =
            item.index ?? supportRateInfo.filterSegment.formIndex ?? 0;
          showFilters.value = true;
          await setCollection(supportRateInfo.filterSegment.curFilter, true);
        } else {
          showFilters.value = false;
          let segmentItem = analyzeObj.originSegments.find((segment: any) => {
            return segment.id === item.segment.id;
          });
          if (segmentItem) {
            const cloneItem = cloneDeep(segmentItem);
            item.index = item.index ?? supportRateInfo.filterSegment.formIndex ?? 0;
            supportRateInfo.segments.splice(item.index, 1, cloneItem);
            supportRateInfo.segmentAnswers[
              item.index
            ] = await supportRateInfo.getSegmentAnswers(item.segment.id);
            await setCollection(cloneItem);
          }
        }
      },
    });

    const wordCloudInfo: any = reactive({
      isLoading: computed(() => {
        return props.item?.word_cloud?.loading;
      }),
      list: [] as any[],
      shape: computed(() => {
        return props.item?.message?.nlp_settings?.shape ?? "circle";
      }),
      colors: computed(() => {
        const color = props.item?.message?.nlp_settings?.color;
        return wordColors.find((item) => item.color === color)?.list ?? ["#000000"];
      }),
      currentTag: WordCloudType.Keywords,
      getTagClass: (tag: string) => {
        return tag === wordCloudInfo.currentTag ? "active" : "";
      },
      typeTags: computed(() => {
        const tags = [] as any[];

        const wordCloud: any = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return tags;
        }

        if (
          wordCloud.nlp_settings?.keywords_enable &&
          roleMng.allowShow(WordCloudType.Keywords)
        ) {
          tags.push(wordCloudTags.find((tag) => tag.value === WordCloudType.Keywords));
        }
        if (wordCloud.nlp_settings?.nlp_enable && roleMng.allowShow(WordCloudType.Nlp)) {
          tags.push(wordCloudTags.find((tag) => tag.value === WordCloudType.Nlp));
        }

        if (
          tags.length > 0 &&
          !tags.find((tag) => tag.value === wordCloudInfo.currentTag)
        ) {
          wordCloudInfo.currentTag = tags[0].value;
        }
        return tags;
      }),
      tagClick: (tag: any) => {
        wordCloudInfo.currentTag = tag.value;
      },
      keywordTags: [] as any[],
      nlpTags: [] as any[],
      participants: computed(() => {
        if (wordCloudInfo.curSegment?.collection) {
          return wordCloudInfo.curSegment?.collection.result.participants;
        }
        return 0;
      }),
      opinions: computed(() => {
     
          if (wordCloudInfo.curSegment?.collection) {
            let ops: any[] =
              Object.values(wordCloudInfo.curSegment.collection.result?.opinions) ?? [];
            const wordCloud = props.item?.collection.ai_analysis_result
            const retrieveMap = wordCloud ? (wordCloud.image_tags ? wordCloud.image_tags : []) : []
            if (retrieveMap) {
              const tags: any[] = wordCloudInfo.getWordTags();
              const idSet = new Set();
              if (tags.length > 0) {
                const keys = tags.map((tag) => tag.name);
                keys.forEach((key) => {
                  retrieveMap.filter((imtags: any) => {
                    if (imtags.image_tags && imtags.image_tags.includes(key)) {
                      idSet.add(Number(imtags.reply_id))
                    }
                  })
                });
                ops = ops.filter((op) => idSet.has(op.reply_id));
              }
            }
            return ops;
          }

        return [];
      }),
      imageTags: [] as any[],
      imageFinished: computed(() => {
        if (wordCloudInfo.curSegment?.collection?.ai_analysis_result?.image_tags?.length > 0) {
          return  wordCloudInfo.curSegment?.collection?.ai_analysis_result?.image_tags.filter((imtags: any) => {
                return imtags.status=='done'
            }).length
        }else{
          return 0
        }
      }),
      imageError: computed(() => {
        if (wordCloudInfo.curSegment?.collection?.ai_analysis_result?.image_tags?.length > 0) {
          return  wordCloudInfo.curSegment?.collection?.ai_analysis_result?.image_tags.filter((imtags: any) => {
                return imtags.status=='error'
            }).length
        }else{
          return 0
        }
      }),
      imageAnalyzing:computed(() => {
        if (wordCloudInfo.curSegment?.collection?.ai_analysis_result?.image_tags?.length> 0) {
          const tags =  wordCloudInfo.curSegment?.collection?.ai_analysis_result?.image_tags;
          const analyzingCount = tags.filter((imtag:any )=> imtag.status != 'done'&&imtag.status != 'error').length;
          return analyzingCount === tags.length;
        } else {
          return false;
        }
      }),
      isEmpty:computed(() => {
        if(!wordCloudInfo.curSegment?.collection?.ai_analysis_result||Object.keys(wordCloudInfo.curSegment?.collection?.ai_analysis_result).length === 0)  return true;
        if(wordCloudInfo.curSegment?.collection?.ai_analysis_result?.image_tags.length>0) {
          return false;
        } else {
          return true;
        }
      }),
      wordList: computed(() => {
        const wordCloud = showFilters.value
          ? wordCloudInfo.filterSegment.curFilter?.word_cloud
          : wordCloudInfo.curSegment?.word_cloud;
        if (!wordCloud) {
          return [];
        }
        let tagList: any[] = []
        if (isNil(wordCloud)) {
          return [];
        }
        const result: any = wordCloud.image_tags;
        if (!result) {
          return [];
        }

        result.map((key: any) => {
          if (key.image_tags && key.image_tags.length > 0) {
            tagList.push(...key.image_tags)
          }
        });
        // 创建一个对象来存储每个标签的出现次数
        const tagCounts: {
          [tag: string]: number;
        } = {};

        // 遍历数组并统计每个标签的出现次数
        tagList.forEach((tag: any) => {
          if (tagCounts[tag]) {
            tagCounts[tag]++;
          } else {
            tagCounts[tag] = 1;
          }
        });
        // 将对象转换为所需的数组格式
        const arr = Object.keys(tagCounts).map(key => ({
          name: key,
          value: tagCounts[key]
        }));
        return arr;
      }),
      downloadWord: () => wordCloudRef.value.downloadWord(),
      refreshWord: () => wordCloudRef.value.initViewData(),
      clickWord: (params: any) => {
        const { data } = params;
        const tags: any[] = wordCloudInfo.getWordTags();
        if (tags.length >= 6 || tags.some((v) => v.name == data.name)) {
          return;
        }
        wordCloudInfo.getWordTags().push(data);
      },
      clearAllWork: () => {
        const tags = wordCloudInfo.getWordTags();
        tags.splice(0, tags.length);
      },
      clearWork: (tag: any, i: number) => {
        const tags: any[] = wordCloudInfo.getWordTags();
        tags.splice(i, 1);
      },
      getWordTags() {
        return wordCloudInfo.currentTag == WordCloudType.Keywords
          ? wordCloudInfo.keywordTags
          : wordCloudInfo.nlpTags;
      },
      getHighlightTags: (opinion: any) => {
        const wordCloud = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return [];
        }
        const result: any = wordCloud[`${wordCloudInfo.currentTag}_retrieve`];
        if (isNil(result)) {
          return [];
        }
        const hightlightSet = new Set();
        const tags: any[] = wordCloudInfo.getWordTags();
        tags.forEach((tag) => {
          const item = result[tag.name];
          if (item.replies.includes(opinion.reply_id)) {
            item.highlight_tags?.forEach((tag: any) => {
              hightlightSet.add(tag);
            });
          }
        });
        return Array.from(hightlightSet);
      },
      curSegment: {
        id: 0,
        name: root.$t("all"),
        collection: null as any,
        word_cloud: null as any,
        selected: [] as any[],
      },
      filterSegment: {
        curFilter: {
          id: -1,
          name: "--",
          collection: null as any,
          word_cloud: null as any,
          selected: [] as any[],
        },
        getName: (selected: any[]) => {
          if (selected.length == 0) {
            return "--";
          }
          return selected
            .map((item: any) => {
              return item.text;
            })
            .join(",");
        },
        handleChange: async (options: any[]) => {
          wordCloudInfo.filterSegment.curFilter.selected = options;
          wordCloudInfo.filterSegment.curFilter.name = wordCloudInfo.filterSegment.getName(
            options
          );
          await setCollection(wordCloudInfo.filterSegment.curFilter, true);
        },
        reset: async () => {
          wordCloudInfo.filterSegment.curFilter.selected = [];
          wordCloudInfo.filterSegment.curFilter.name = "--";
          setCollection(wordCloudInfo.filterSegment.curFilter, true);
        },
      },
      handleCommand: async (item: any) => {
        if (!item.segment) {
          showFilters.value = true;
          wordCloudInfo.curSegment = wordCloudInfo.filterSegment.curFilter;
          await setCollection(wordCloudInfo.filterSegment.curFilter, true);
        } else {
          showFilters.value = false;
          let segmentItem = analyzeObj.originSegments.find((segment: any) => {
            return segment.id === item.segment.id;
          });
          if (segmentItem) {
            const item = cloneDeep(segmentItem);
            await setCollection(item, true);
            wordCloudInfo.curSegment = item;
          }
        }
        wordCloudInfo.clearAllWork();
      },
      enableClass: (segment: any, itemSeg: any) => {
        if (segment.id >= 0) {
          return segment.id == itemSeg?.id ? "item-enable" : "";
        } else {
          return itemSeg.id < 0 ? "item-enable" : "";
        }
      },
    });
    wordCloudInfo.list = cloneDeep(wordCloudInfo.wordList);

    const analyzeObj: any = reactive({
      loading: true,
      overallSegment: {
        id: 0,
        name: root.$t("all"),
        collection: props.item?.collection,
        word_cloud: props.item?.word_cloud,
      },
      originSegments: computed(() => {
        const items = segments.value.map((segment: any) => {
          const item = {
            id: segment.id,
            name: segment.name,
            collection: null,
          };
          return item;
        });
        return [analyzeObj.overallSegment, ...items];
      }),

      getParticipants: (item: any) => {
        if (!item?.collection) {
          return 0;
        }
        return item.collection.result.participants;
      },

      getOpinions: (segment: any) => {
        if (!segment?.collection) {
          return {};
        }
        return segment?.collection?.result?.opinions;
      },

      getPercentage: (segment: any, reply_id: number) => {
        const opinions = analyzeObj.getOpinions(segment);
        const opinion = opinions[reply_id];
        if (!opinion) {
          return undefined;
        }
        return Math.round(opinion.agree_rate * 100);
      },

      getNumber: (segment: any, reply_id: number) => {
        const opinions = analyzeObj.getOpinions(segment);
        const opinion = opinions[reply_id];
        if (!opinion) {
          return undefined;
        }
        return opinion.agree + opinion.disagree + opinion.lose;
      },

      enableClass: (segment: any, itemSeg: any) => {
        if (segment.id >= 0) {
          return segment.id == itemSeg.id ? "item-enable" : "";
        } else {
          return itemSeg.id < 0 ? "item-enable" : "";
        }
      },
    });

    const setCollection = async (item: any, hidden = false) => {
      if (item.id == 0) {
        item.collection = props.item?.collection;
        item.word_cloud = props.item?.collection.ai_analysis_result;
      } else if (item.id > 0) {
        const res = await getMessageSegmentAnalyzeApi({
          uuid: props.item?.message.project_uuid,
          mid: props.item?.message.message_identifier,
          id: item.id,
          driver: driver.value,
          display: hidden ? "none" : "",
          observer: observerInfo.isObserver,
          analysis_type: contentType.value
        });
        if (res.length > 0) {
          item.collection = res[0].collection;
          item.word_cloud = res[0].collection.ai_analysis_result;
        }
      }
    };

    onMounted(async () => {
      wordCloudInfo.imageTags = props.item?.collection.ai_analysis_result?.
        image_tags ?? [];
      roleMng.allowRefresh = roleMng.isAllowRefresh()
      initData();
    });

    onUnmounted(() => {
      supportRateInfo.segments.forEach((segment: any) => {
        segment.collection = null;
      });
      supportRateInfo.filterSegment.segments.forEach((segment: any) => {
        segment.collection = null;
      });
      updateAnalyzeSegments(props.item?.message.project_uuid, supportRateInfo.segments);
      updateFilterSegments(
        props.item?.message.project_uuid,
        supportRateInfo.filterSegment.segments
      );
    });

    const initData = async () => {

      supportRateInfo.answerSegment.answers = props.item?.collection?.result.opinions
        ? Object.values(props.item?.collection?.result.opinions)
        : [];

      const segs = getAnalyzeSegments(props.item?.message.project_uuid);
      supportRateInfo.segments =
        segs && segs.length > 0 ? cloneDeep(segs) : analyzeObj.originSegments.slice(0, 1);


      supportRateInfo.segmentAnswers = await Promise.all(
        supportRateInfo.segments.map(async (segment: any) => {
          return await supportRateInfo.getSegmentAnswers(segment.id);
        })
      );
      setCollection(wordCloudInfo.curSegment, true);
    };

    const copy_text = (segment_index: any) => {
      root.$doCopy(
        supportRateInfo.segmentAnswers[segment_index]
          .map((i: any) => '"' + i.text.replace('"', '"') + '"')
          .join("\n")
      );
    };

    watch(
      () => props.value,
      () => {
        showDrawer.value = props.value;
      }
    );




    watch(
      () => {
        return props.item;
      },
      async (value, old) => {
        analyzeObj.loading = !isEqual(value, old);
        wordCloudInfo.imageTags = props.item?.collection.ai_analysis_result?.
          image_tags ?? [];
        roleMng.allowRefresh = roleMng.isAllowRefresh()
        if (showFilters.value) {
          await setCollection(supportRateInfo.filterSegment);
        } else {
          await Promise.all(
            supportRateInfo.segments.map(async (segment: any) => {
              await setCollection(segment);
            })
          );
          await setCollection(wordCloudInfo.curSegment, true);
        }
        await supportRateInfo.handleSegmentChange(supportRateInfo.answerSegment);

      }
    );

    // watch(
    //   () => roleMng.allowShowWordCloud,
    //   (val) => {
    //     if (!val) {
    //       contentType.value = AnalyzeTab.WordCloud;
    //     }
    //   }
    // );

    watch(
      () => wordCloudInfo.wordList,
      (val, old) => {
        const isChange = isEqual(val, old);
        if (isChange || wordCloudInfo.list.length === 0) {
          wordCloudInfo.list = cloneDeep(val);
        }
      },
      { deep: true }
    );

    const refreshAnalysis = async () => {
      try {
        const result = await getAnalysisImageTags({ id: props.item?.collection.message_id })
        roleMng.allowRefresh = false
      } catch (error) {
        showError(error)
      }
    }
    return {
      OrderType,
      showDrawer,
      showFilters,
      analyzeObj,
      getPercentageVarietyStyle,
      onClosed,
      wordCloudRef,
      wordCloudInfo,
      contentType,
      onShowWordCloudDialog,
      onShowAIAnalyzeDialog,
      AnalyzeTab,
      supportRateInfo,
      roleMng,
      getHighlightText,
      copy_text,
      projectInfo,
      ProjectStatus,
      refreshAnalysis
    };
  },
});
