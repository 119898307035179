









































































import Editor from '@/components/Editor.vue'
import TextCheckbox from './components/TextCheckbox.vue'
import { EntityType } from '@/api/project/model'
import { defineComponent, inject, reactive, ref } from '@vue/composition-api'
import { Checkbox, CheckboxGroup, Popover } from 'element-ui'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
import { typeDurationOption } from '@/api/project/duration-option'
import WordCloudBox from './components/WordCloudBox.vue'
import SettingBox from '@/components/SettingBox.vue'

export default defineComponent({
    components: {
        Editor,
        TextCheckbox,
        TimeCustomSelect,
        WordCloudBox,
        [Popover.name]: Popover,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        SettingBox,
    },
    setup(props, { refs }) {
        const itemObj: any = inject('itemObj')
        const itemWay: any = inject('itemWay')

        if (itemWay.value == 'create') {
            itemObj.message_entity.entity_type = EntityType.branch
            itemObj.message_entity.duration = typeDurationOption[EntityType.branch].value
        }

        if (itemObj.message_entity.settings.length === 0) {
            itemObj.message_entity.settings.push({
                ai_enable: true,
                options: {
                    randomized: false,
                    other: false,
                    none: false,
                },
                recommend_enable: true,
            });
        }

        const branchActiveIndex = ref(0)

        const addBranch = async () => {
            const data = {
                name: '--',
                message_entity_id: itemObj.message_entity.id,
                options: [],
                content: ''
            }
            itemObj.message_entity.branchs.push(data)
        }

        const deleteBranch = async (index: number) => {
            ;(<any>refs.popoverRef)[index].doClose()
            branchActiveIndex.value = 0
            itemObj.message_entity.branchs.splice(index, 1)
        }

        const copyContentToAllBranch = async () => {
            const copyContent = itemObj.message_entity.branchs[branchActiveIndex.value].content
            itemObj.message_entity.branchs.forEach((branch: any) => {
                branch.content = copyContent
            })
        }

        const chooseOption = async (item: any, obj: any) => {
            const isAdd = item.options.findIndex((option: any) => option.message_option_id === obj.id) === -1
            if (isAdd) {
                item.options.push({
                    message_option_id: obj.id
                })
            } else {
                const index = item.options.findIndex((option: any) => option.message_option_id === obj.id)
                item.options.splice(index, 1)
            }
            const name = itemObj.message_entity.parent_message_entity.options
                .filter((v: any) => item.options.map((r: any) => r.message_option_id).includes(v.id))
                .map((z: any) => z.text)
                .toString()
            item.name = name || '--'
        }

        const handleParentOptionList = (optionItem: any, index: number) => {
            const parentOptionAry: any[] = []
            const otherOption = itemObj.message_entity.branchs.filter((v: any, i: number) => i != index)
            const otherList: any[] = []
            otherOption.forEach((v: any) => {
                otherList.push(...v.options)
            })

            const handleOther = (item: any) => {
                return otherList.some((v: any) => v.message_option_id == item.id)
            }

            const handleLength = (item: any) => {
                // if (optionItem.options.length >= 3) {
                //     return !optionItem.options.some((v: any) => v.message_option_id == item.id)
                // }
                return false
            }

            itemObj.message_entity.parent_message_entity.options.forEach((item: any) => {
                const info = {
                    ...item,
                    disabled: handleLength(item) || handleOther(item)
                }
                parentOptionAry.push(info)
            })

            return parentOptionAry
        }

        const optionIsCheck = (item: any, obj: any) => {
            for (let i = 0; i < item.options.length; i++) {
                if (item.options[i].message_option_id == obj.id) {
                    return true
                }
            }
            return false
        }

        enum OptionName {
            A,
            B,
            C
        }

        return {
            itemObj,
            EntityType,
            branchActiveIndex,
            addBranch,
            deleteBranch,
            chooseOption,
            optionIsCheck,
            OptionName,
            handleParentOptionList,
            copyContentToAllBranch
        }
    }
})
